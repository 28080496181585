import { useTranslation } from 'react-i18next';
import { DrmDivider, DrmRow, DrmSubtitle } from '../DrmDetailComponents';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { OverflowText, ViewMore } from '@components/common';
import { Grid } from '@mui/material';
import { NmrDrmDetail } from '@services/nmr';
import { FC } from 'react';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

type TTheoreticalSpectrumDetails = {
	drm?: NmrDrmDetail;
};

export const TheoreticalSpectrumDetails: FC<TTheoreticalSpectrumDetails> = ({ drm }) => {
	const { t } = useTranslation('admin');

	const isActive = drm?.status === UserReferenceStatus.Active;
	const SubstanceDetails = () => {
		return (
			<>
				<DrmSubtitle isActive={isActive}>
					<Tr.Admin path="substance-detail.substance-details" />
				</DrmSubtitle>
				<DrmRow label={t('substance-detail.cas-number')} result={drm?.casNumber} isActive={isActive} />
				<DrmRow label={t('substance-detail.molecular-formula')} result={drm?.linearFormula} isActive={isActive} />
				<DrmRow
					label={t('substance-detail.molecular-weight')}
					result={`${drm?.molecularWeight} ${t('substance-detail.molecular-weight-unit')}`}
					isActive={isActive}
				/>
				<DrmRow label={t('substance-detail.smiles')} result={drm?.smilesCode} isActive={isActive} />
			</>
		);
	};

	const AdditionalInformation = () => {
		return (
			<>
				<DrmSubtitle isActive={isActive}>
					<Tr.Admin path="substance-detail.additional-information" />
				</DrmSubtitle>

				<DrmRow
					label={t('substance-detail.release-date')}
					result={drm?.createTime ? DateUtils.getFormattedDate(drm.createTime, 'dd/MM/yyyy') : '-'}
					isActive={isActive}
				/>
				<DrmRow label={t('substance-detail.reference-code')} result={drm?.ermProductNumber} isActive={isActive} />

				<Grid item>
					<Grid container alignItems="start" columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 16 }}>
						<Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
							<OverflowText variant="pg-m" width="100%" color={isActive ? 'text.primary' : 'grey.500'}>
								<Tr.Admin path="substance-detail.synonyms" />
							</OverflowText>
						</Grid>
						<Grid item xs={12} sm={4} md={6} lg={7} xl={11}>
							<ViewMore
								content={drm?.synonyms || '-'}
								typographyProps={{
									variant: 'pg-m',
									sx: {
										color: isActive ? 'grey.800' : 'grey.500',
									},
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	};

	return (
		<>
			<SubstanceDetails />
			<DrmDivider />
			<AdditionalInformation />
		</>
	);
};
