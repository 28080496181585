import { Accept } from 'react-dropzone';

enum UploadableFileExtensions {
	DEVICE = 'Device',
	HEALTH_CHECK = 'HealthCheck',
	NMR_PIPELINE = 'NmrPipeline',
	IR_ANALYSIS = 'IrAnalysis',
	IR_DEVICE = 'IrDevice',
}
const validExtensions = Array.from({ length: 1000 }, (_, i) => `.${i}`);
export const UploadableFileExtensionMapping: Record<UploadableFileExtensions, Accept> = {
	[UploadableFileExtensions.DEVICE]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx'],
	},
	[UploadableFileExtensions.HEALTH_CHECK]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx'],
	},
	[UploadableFileExtensions.NMR_PIPELINE]: {
		'text/csv': ['.csv'],
	},
	[UploadableFileExtensions.IR_ANALYSIS]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx', '.spa', '.spc', '.0', '.sp'],
		'application/octet-stream': validExtensions,
	},
	[UploadableFileExtensions.IR_DEVICE]: {
		'chemical/x-jcamp-dx': ['.jdx', '.dx', '.spa', '.spc', '.0', '.sp'],
		'application/octet-stream': validExtensions,
	},
};
