import { FC } from 'react';
import { Tr } from '@utils/Translation';
import { getIn, useFormik } from 'formik';
import { Currency } from '@models/subscribe';
import { DataTestId } from '@utils/DataTestId';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Grid, Stack, StackProps, Typography } from '@mui/material';
import { FormikAutoComplete, FormikTextfield, ModalDialog } from '@components/common';
import { getTechnologyValidationSchema } from '@schemas';
import { SupportedTechnology, TechnologyObject } from '@services/nmr/nmr-analysis/technology.enum';
import { formFieldSpacer } from '@utils/Theme';
import { FormUtils } from '@utils/Form/FormUtils';

interface IAddTechnologyModalProps {
	isOpen: boolean;
	onCancel?: () => void;
	onSave?: (technology: string, price: number) => void;
	technologyOptions: SupportedTechnology[];
	paidBudget?: number;
	currency: Currency;
}

const fullWidth: StackProps = { width: 1 };
type FormFields = Extendable<TechnologyObject>;

export const AddTechnologyModal: FC<IAddTechnologyModalProps> = ({ isOpen, onCancel, onSave, technologyOptions, currency, paidBudget }) => {
	const { t } = useTranslation('admin');

	const formik = useFormik<FormFields>({
		initialValues: {
			technology: '',
			price: undefined,
		},
		validationSchema: getTechnologyValidationSchema(t, paidBudget),
		onSubmit: ({ technology, price }) => {
			onSave && onSave(technology, price);
		},
	});
	return (
		<ModalDialog
			variant="secondary"
			open={isOpen}
			title={
				<Typography variant="pg-s">
					<Tr.Admin path="organization.add" />
				</Typography>
			}
			closeIconClick={onCancel}
			maxWidth={false}
			PaperProps={{
				sx: {
					maxWidth: '472px',
					maxHeight: '430px',
					minHeight: '430px',
				},
			}}
		>
			<form onSubmit={formik?.handleSubmit}>
				<Stack
					sx={{
						paddingX: '68px',
						marginY: 4,
					}}
					spacing={4}
				>
					<Stack direction="row" justifyContent="center" textAlign="center">
						<Typography variant="h3" textTransform="capitalize">
							<Tr.Admin path="organization.add-technology" />
						</Typography>
					</Stack>
					<Stack spacing={formFieldSpacer}>
						<FormikAutoComplete
							formik={formik}
							value={formik?.values.technology || null}
							title={t('organization.technology')}
							onSelectionChange={(value) => {
								formik?.setFieldValue('technology', value || '');
							}}
							select={false}
							options={technologyOptions}
							name="technology"
							placeholder={t('organization.technology-placeholder')}
							variant="outlined"
							autocompleteProps={{
								disablePortal: true,
							}}
							data-testid={DataTestId.getStaticTestId('add-tech-tech-id')}
							required
						/>
						<Stack sx={{ position: 'relative' }}>
							<FormikTextfield
								formikApi={formik}
								name="price"
								value={formik.values.price}
								onKeyDown={FormUtils.preventNonDecimal}
								type="number"
								title={t('organization.price')}
								placeholder={t('organization.price-placeholder')}
								variant="outlined"
								sx={{ maxLength: 10, step: 'any' }}
								containerProps={fullWidth}
								data-testid={DataTestId.getStaticTestId('add-tech-price-id')}
								required={true}
								InputProps={{
									endAdornment: (
										<Chip
											size="small"
											variant="outlined"
											color={getIn(formik.errors, 'price') ? 'error' : 'success'}
											label={<Typography variant="subtitle3">{currency}</Typography>}
										/>
									),
								}}
							/>
						</Stack>

						<Grid container paddingTop={1}>
							<Grid
								item
								xs={6}
								sx={{
									paddingRight: 1,
								}}
							>
								<Button
									variant="outlined"
									fullWidth={true}
									data-testid="add-tech-cancel-button-id"
									disableElevation
									onClick={() => {
										onCancel && onCancel();
									}}
								>
									<Tr.Portal path="cancel" />
								</Button>
							</Grid>

							<Grid
								item
								xs={6}
								sx={{
									paddingLeft: 1,
								}}
							>
								<Button
									fullWidth={true}
									data-testid="add-tech-approve-button-id"
									variant="contained"
									type="submit"
									disableElevation
									disabled={!formik?.dirty || !formik?.isValid}
									sx={{ textTransform: 'capitalize' }}
								>
									<Tr.Portal path="add" />
								</Button>
							</Grid>
						</Grid>
					</Stack>
				</Stack>
			</form>
		</ModalDialog>
	);
};
