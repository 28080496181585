import * as yup from 'yup';
import { TFunction } from 'i18next';

export const getNmrUserReferenceValidationSchema: (t: TFunction, editing?: boolean) => yup.AnySchema = (t) =>
	yup.object().shape({
		status: yup.string().nullable(),
		name: yup.string().required(t('user-references.reference-required')),
		nmrReferenceMaterialLibraryId: yup.string().required(t('user-references.library-required')),
		casNumber: yup.string().nullable(),
		linearFormula: yup.string().nullable(),
		molecularWeight: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		smilesCode: yup.string().nullable().required(t('user-references.smiles-required')),
		synonyms: yup.string().nullable(),

		solvent: yup.string().nullable(),
		frequency: yup.string().nullable(),
		relaxationTime: yup.string().nullable(),
		numberOfScans: yup.number().nullable().typeError(t('user-references.value-must-a-number')),
		acquisitionTime: yup.string().nullable(),
		temperature: yup.string().nullable(),
		batchNumber: yup.string().nullable(),
		generalComment: yup.string().nullable(),
	});
