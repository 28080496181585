import { ActiveState, Body1, EditButton } from '@components/common';
import { useHasPermissions } from '@hooks';
import { ExtendedColumn } from '@models/table';
import { AdminPermission, UserResponseModel, UserStatus } from '@models/user';
import { Stack } from '@mui/material';
import { DataTestId } from '@utils/DataTestId';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IAdminUserColumns {
	onEditClick: (user: UserResponseModel) => void;
}

const isUserActive = (status: UserStatus) => status === UserStatus.ACTIVE;

export const AdminUserColumns: (arg0: IAdminUserColumns) => ExtendedColumn<UserResponseModel>[] = ({ onEditClick }) => {
	const { t } = useTranslation('common');
	const hasEditPermission = useHasPermissions(AdminPermission.EDIT_ADMIN_USER);

	return useMemo<ExtendedColumn<UserResponseModel>[]>(
		() => [
			{
				accessor: 'status',
				Header: (
					<Stack marginLeft={1}>
						<Tr.Common path="status" />
					</Stack>
				),
				Cell: (props) => (
					<Stack marginLeft={1}>
						<ActiveState status={props.cell.value?.toUpperCase()} />
					</Stack>
				),
			},
			{
				accessor: 'fullName',
				Header: (
					<Stack>
						<Tr.Common path="name" />
					</Stack>
				),
				Cell: ({ value, row }) => <Body1 status={isUserActive(row.original.status)}>{value ?? '-'}</Body1>,
			},
			{
				accessor: 'lastActivity',
				Header: (
					<Stack>
						<Tr.Common path="last-active-date" />
					</Stack>
				),
				Cell: ({ value, row }) => (
					<Body1 status={isUserActive(row.original.status)}>
						{value ? DateUtils.getFormattedDate(new Date(value), 'dd/MM/yyyy', '-') : '-'}
					</Body1>
				),
			},
			{
				accessor: 'email',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Common path="email" />
					</Stack>
				),
				Cell: ({ value, row }) => <Body1 status={isUserActive(row.original.status)}>{value ?? '-'}</Body1>,
			},
			{
				accessor: 'roles',
				disableSortBy: true,
				Header: (
					<Stack>
						<Tr.Common path="role" />
					</Stack>
				),
				Cell: ({ value, row }) => (
					<Body1 status={isUserActive(row.original.status)}>{value.map((role) => t(`roles.${role}`)).join(', ') ?? '-'}</Body1>
				),
			},
			{
				accessor: 'id',
				Header: <></>,
				sticky: true,
				disableSortBy: true,
				Cell: ({ row }) =>
					hasEditPermission && (
						<EditButton
							data-testid={DataTestId.getStaticTestId(`edit-button-${row.index}`)}
							onClick={() => onEditClick(row.original)}
						/>
					),
			},
		],
		[hasEditPermission],
	);
};
