import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Reason, useNmrParser, usePageLeaveDetection, useService, useDebounce, useAnalysis, MetaDataType } from '@hooks';
import { analyticsAnalysisEvent, checkFileIsValid, RxUtils, TypeUtils } from '@utils';
import { AnalysisTargetType, IUploadedFile, PortalPageRoutes, QualitativeOrQuantitative } from '@models';
import {
	notificationService,
	nmrAnalysisService,
	NMRAnalysisResultMatchType,
	NmrAnalysisStage,
	getNmrAnalysisRoute,
	nmrDeviceService,
	NmrDevice,
	loadingSpinnerOverlayService,
} from '@services';
import { Stack } from '@mui/material';
import { NmrAnalysisDetail } from './NmrAnalysisDetail';
import { NmrAnalyticalProcedure } from './NmrAnalyticalProcedure';
import { NmrSampleMeasurements } from './NmrSampleMeasurements';
import { NmrAnalysisTitle } from './NmrAnalysisTitle';
import { LoadingLayout } from '@components/common';
import { analyticsErmEvent } from '@utils/Analytics/erm-events';
import { FormFields, initialFormValues } from './analysis-form';
import { getAnalysisValidation } from '@schemas';
import { AnalysisSelectionType, AnalysisSelectionTypeMap, getIsQuantitiveNonTargeted, TAnalysisSelectionType } from '@models';
import { QuantitiveNonTargetedProcedure } from './QuantitiveNonTargetedProcedure';
import { isAnalysisPossible } from '@components/nmr-portal';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/slices/common/common.slice';

const DownloadFileExtension = ['.jdf', '.zip'];

export const NmrAnalysis: FC = () => {
	const { t } = useTranslation('portal');
	const navigate = useNavigate();
	const { id = '' } = useParams<{ id: string }>();
	const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined);
	const [allCompoundAssignedError, setAllCompoundAssignedError] = useState<boolean>(false);
	const [spectrumFile, setSpectrumFile] = useState<Partial<IUploadedFile>>({ content: null });
	const [lastSavedTime, setLastSavedTime] = useState<Date>();
	const [fileUploadLoader, setFileUploadLoader] = useState(false);
	const { data: analysisData, loading } = useService(() => nmrAnalysisService.get(+id), []);
	const [isAnalysisFilesLoading, setAnalysisFilesLoading] = useState(true);
	const { meta } = useNmrParser(spectrumFile.content || '');
	const { debounce } = useDebounce(500);
	const {
		nmrDevice,
		updateNmrDevice,
		measurementType,
		updateMeasurementType,
		targetType,
		updateTargetType,
		updateMetaData,
		metaData,
		updateAnalysisEditable,
		allCompoundsAssigned,
	} = useAnalysis();

	const user = useSelector(userSelector);

	const formik = useFormik<FormFields>({
		validateOnChange: false,
		validateOnBlur: false,
		validateOnMount: false,
		initialValues: initialFormValues.initialValues,
		initialTouched: initialFormValues.initialTouched,
		validationSchema: getAnalysisValidation(t, measurementType),
		onSubmit: () => runAnalysis(),
	});

	useMemo(() => {
		debounce(() => formik.validateForm());
	}, [formik.values]);

	useMemo(() => {
		if (analysisData?.operatorId && user?.username) updateAnalysisEditable(analysisData?.operatorId === user?.username);
	}, [analysisData?.operatorId, user?.email]);

	// onChange Function
	const onAnalysisTypeChange = (value: AnalysisSelectionType) => {
		const analysisSelectionType = TypeUtils.returnValueOfKey<typeof AnalysisSelectionTypeMap, TAnalysisSelectionType>(
			value,
			AnalysisSelectionTypeMap,
		);
		const formikTargetType = analysisSelectionType.targetType ?? AnalysisTargetType.NON_TARGETED;
		const mappedMeasurementType = analysisSelectionType.measurementType;
		RxUtils.promisify(nmrAnalysisService.changeAnalysisType(+id, mappedMeasurementType, formikTargetType), () => {
			updateMeasurementType(mappedMeasurementType);
			updateTargetType(formikTargetType);
			debounce(() => formik.validateForm());
		});
	};

	const runAnalysis = async () => {
		let executeAnalysis = true;
		if (measurementType === QualitativeOrQuantitative.QUANTITATIVE && targetType === AnalysisTargetType.NON_TARGETED) {
			if (!allCompoundsAssigned) {
				setAllCompoundAssignedError(true);
				executeAnalysis = false;
			}
		}
		if (executeAnalysis) {
			analysisData && (analysisData.status = NMRAnalysisResultMatchType.NOMATCH);
			analysisData && (analysisData.stage = NmrAnalysisStage.EXECUTING);
			analyticsAnalysisEvent.AnalysisExecuting(id); // For Google analytics
			const successCb = () => {
				analyticsAnalysisEvent.Executed(id);
				RxUtils.promisify(nmrAnalysisService.getDRMs(+id), (drms) => {
					drms.forEach((usedErm) => analyticsErmEvent.ErmUsed(usedErm.name));
				});
				navigate(`../${PortalPageRoutes.ANALYSIS_RESULT}/${id}`);
			};
			const errorCb = () => {
				analyticsAnalysisEvent.AnalysisError(id);
			};
			updateAnalysis(false, successCb, errorCb);
		}
	};
	const downloadOtherFileTypes = (filename: string, fileId: number) => {
		RxUtils.promisify(nmrAnalysisService.downloadFile(+id, fileId), (fileContent) => {
			setUploadedFileName(filename);
			setSpectrumFile({ content: fileContent });
			notificationService.sendSuccess(t('new-analysis.success-file-upload'));
		});
	};

	const onUploadedFileChange = (file?: IUploadedFile) => {
		if (!file || !checkFileIsValid(file?.content || '')) {
			notificationService.sendError(t('file-invalid'));
			return;
		}
		setFileUploadLoader(true);
		RxUtils.promisify(
			nmrAnalysisService.uploadFile(+id, file),
			({ id: fileId }) => {
				if (DownloadFileExtension.some((item) => file.file.name.toLocaleLowerCase().includes(item))) {
					downloadOtherFileTypes(file.file.name, fileId);
				} else {
					setUploadedFileName(file.file.name);
					setSpectrumFile({ content: file.content });
					notificationService.sendSuccess(t('new-analysis.success-file-upload'));
				}
			},
			() => undefined,
			() => setFileUploadLoader(false),
		);
	};

	const updateAnalysis = (noLoader = false, successCb: (res?: unknown) => void, errorCb: () => void) => {
		return RxUtils.promisify(
			nmrAnalysisService.update(
				{
					...analysisData,
					...formik?.values,
					stage: analysisData?.stage,
					probhd: meta?.PROBHD,
					status: analysisData?.status,
					receiverGain: meta?.RG ? +meta?.RG : 0,
					frequency: meta?.['.OBSERVEFREQUENCY'] || null,
					deviceCalibrationId: !formik?.values.deviceCalibrationId ? undefined : +formik?.values.deviceCalibrationId,
				},
				noLoader,
			),
			(res) => successCb(res),
			() => errorCb(),
		);
	};

	usePageLeaveDetection(
		(reason) => {
			const checkDataAvailable = () => !!meta && formik?.dirty;
			if (reason === Reason.TIMER) {
				const successCb = (value: unknown) => {
					const formValues = value as FormFields;
					formik?.resetForm({
						values: {
							...formValues,
							deviceCalibrationId: formValues.deviceCalibrationId,
							measurementDeviceBrand: nmrDevice?.manufacturer || null,
						},
					});
					formik?.validateForm({ ...formik?.values });
					setLastSavedTime(new Date());
				};
				checkDataAvailable() &&
					!analysisData?.isExecuting() &&
					!analysisData?.isExecuted() &&
					updateAnalysis(true, successCb, () => undefined);
			} else if (reason === Reason.NAVIGATION) {
				checkDataAvailable() &&
					!analysisData?.isExecuted() &&
					!analysisData?.isExecuting() &&
					updateAnalysis(
						true,
						() => undefined,
						() => undefined,
					);
			}
			return true;
		},
		2000,
		() => !!meta && (formik?.dirty as boolean),
		[updateAnalysis],
	);

	useEffect(() => {
		if (analysisData) {
			loadingSpinnerOverlayService.increment();
			formik?.setFieldValue('secondApproval', analysisData.secondApproval);
			updateMeasurementType(analysisData.measurementType);
			updateTargetType(analysisData.targetType);
			if (getNmrAnalysisRoute(analysisData.stage) !== PortalPageRoutes.ANALYSIS) {
				navigate(`../${getNmrAnalysisRoute(analysisData.stage)}/${id}`);
				return;
			}

			const disableLoading = () => (setAnalysisFilesLoading(false), loadingSpinnerOverlayService.decrement());
			RxUtils.promisify(
				nmrAnalysisService.getFiles(+id),
				(data) => {
					if (data.length > 0) {
						RxUtils.promisify(
							nmrAnalysisService.downloadFile(+id, data[0].id),
							(file) => {
								setSpectrumFile({ content: file } as any);
							},
							disableLoading,
							disableLoading,
						);

						formik?.resetForm({
							values: { ...analysisData },
							isValidating: true,
						});
					} else {
						loadingSpinnerOverlayService.decrement();
						setAnalysisFilesLoading(false);
					}
				},
				disableLoading,
			);
			// TODO: find better solution
			const timer = setTimeout(() => {
				formik?.validateForm();
			}, 50);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [analysisData]);

	const handleDeviceErrorCallback = () => {
		updateNmrDevice(null);
	};

	useEffect(() => {
		updateMetaData(meta as MetaDataType);
		if (meta?.PROBHD) {
			RxUtils.promisify(
				nmrDeviceService.getDeviceByProbeId(meta.PROBHD),
				(deviceResponse: NmrDevice) => {
					RxUtils.promisify(
						nmrDeviceService.getDevice(deviceResponse.id),
						(nmrDeviceResponse: NmrDevice) => {
							updateNmrDevice(nmrDeviceResponse || null);
						},
						handleDeviceErrorCallback,
					);
				},
				handleDeviceErrorCallback,
			);
		}
	}, [meta]);

	const { isAnalysisProceedable, isQuantitiveNonTargeted } = useMemo(
		() => ({
			isAnalysisProceedable: measurementType && isAnalysisPossible(nmrDevice, metaData, measurementType),
			isQuantitiveNonTargeted: getIsQuantitiveNonTargeted(measurementType, targetType),
		}),
		[measurementType, metaData, targetType, nmrDevice],
	);

	const showAnalysisComponents = !loading && !isAnalysisFilesLoading && spectrumFile;
	return (
		<form onSubmit={formik?.handleSubmit} data-testid="analysis-initial-upload-id">
			{fileUploadLoader && <LoadingLayout sx={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }} />}
			<Stack direction="column" data-testid="analysis-test-id">
				{!isAnalysisFilesLoading && spectrumFile && !!user?.email && (
					<>
						<NmrAnalysisTitle formik={formik} hasFile={!!spectrumFile.content} savedTime={lastSavedTime} />
						<NmrSampleMeasurements
							formik={formik}
							spectrumFile={spectrumFile}
							onUploadedFileChange={onUploadedFileChange}
							onAnalysisTypeChange={onAnalysisTypeChange}
						/>
					</>
				)}
				{showAnalysisComponents ? (
					<Stack>
						<NmrAnalysisDetail
							isAnalysisProceedable={isAnalysisProceedable ?? false}
							formik={formik}
							fileName={uploadedFileName || ''}
							analysisData={analysisData as any}
							uploadedFile={!!uploadedFileName}
						/>
					</Stack>
				) : null}
				{showAnalysisComponents && isAnalysisProceedable && !isQuantitiveNonTargeted ? (
					<NmrAnalyticalProcedure
						formik={formik}
						id={+id}
						isFormValid={formik?.isValid}
						onStageChange={(stage) => analysisData && (analysisData.stage = stage as unknown as NmrAnalysisStage)}
					/>
				) : null}

				{showAnalysisComponents && !isAnalysisFilesLoading && isAnalysisProceedable && isQuantitiveNonTargeted ? (
					<QuantitiveNonTargetedProcedure
						setAllCompoundAssignedError={setAllCompoundAssignedError}
						allCompoundAssignedError={allCompoundAssignedError}
						formik={formik}
					/>
				) : null}
			</Stack>
		</form>
	);
};
