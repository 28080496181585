import { MolecularFormula, OverflowText } from '@components/common';
import { Typography, Grid, useMediaQuery, Theme, GridProps, Tooltip } from '@mui/material';
import { Substance } from '@services/core/substance';
import { DataTestId } from '@utils/DataTestId';
import { Tr } from '@utils/Translation';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type TMolecularFormulaDimensions = {
	width: number;
	height: number;
};

type TSubstanceCardProps = {
	substance?: Substance;
	containerProps?: GridProps;
	lastColumnProps?: GridProps;
	firstColumnProps?: GridProps;
	secondColumnProps?: GridProps;
	explanationGridProps?: GridProps;
	molecularFormulaDimensions: TMolecularFormulaDimensions;
	title?: ReactNode;
};

export const SubstanceCard: FC<TSubstanceCardProps> = ({
	substance,
	containerProps,
	lastColumnProps,
	firstColumnProps,
	secondColumnProps,
	molecularFormulaDimensions,
	explanationGridProps,
	title,
}) => {
	const media = useMediaQuery((theme: Theme) => theme.breakpoints.down(1350));
	const { t } = useTranslation('admin');
	const molecularFormulaRef = React.createRef<HTMLDivElement>();
	return (
		<Grid container {...containerProps}>
			<Grid item xs={4} {...firstColumnProps}>
				<MolecularFormula
					width={molecularFormulaDimensions.width}
					height={media ? 150 : 280}
					sx={{ width: '100%', height: molecularFormulaDimensions.height }}
					smilesFormula={substance?.smilesCode}
					ref={molecularFormulaRef}
				/>{' '}
			</Grid>
			<Grid item xs={8} {...secondColumnProps}>
				{typeof title === 'string' ? (
					<Grid item xs={12}>
						<Tooltip
							placement="top"
							PopperProps={{
								sx: {
									maxHeight: '50%',
									zIndex: 1000000,
									'.MuiTooltip-tooltip': {
										minWidth: '200px',
										maxWidth: '400px',
										width: 'auto',
										padding: 1,
									},
								},
							}}
							title={title}
						>
							<Typography
								variant="h2"
								sx={{
									display: 'block',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									maxWidth: '100%',
									minWidth: '0',
								}}
							>
								{title}
							</Typography>
						</Tooltip>
					</Grid>
				) : (
					title
				)}

				<Grid container marginBottom={1.5} marginTop={2} rowGap={1.5} {...explanationGridProps}>
					<Grid item xs={4}>
						<Typography variant="pg-m">
							<Tr.Admin path="substance-detail.cas-number" />
						</Typography>
					</Grid>
					<Grid item xs={8} {...lastColumnProps}>
						<Typography
							sx={{ color: 'grey.800' }}
							variant="pg-m"
							data-testid={DataTestId.getStaticTestId('substance-cas-number-id')}
						>
							{substance?.casNumber ?? '-'}
						</Typography>
					</Grid>

					<Grid item xs={4}>
						<Typography variant="pg-m">
							<Tr.Admin path="substance-detail.molecular-formula" />
						</Typography>
					</Grid>
					<Grid item xs={8} {...lastColumnProps}>
						<Typography sx={{ color: 'grey.800' }} variant="pg-m">
							{substance?.linearMolecularFormula ?? '-'}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="pg-m">
							<Tr.Admin path="substance-detail.molecular-weight" />
						</Typography>
					</Grid>
					<Grid item xs={8} {...lastColumnProps}>
						<Typography sx={{ color: 'grey.800' }} variant="pg-m">
							{substance?.molecularWeight
								? `${substance?.molecularWeight} ${t('substance-detail.molecular-weight-unit')}`
								: '-'}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="pg-m">
							<Tr.Admin path="substance-detail.smiles" />
						</Typography>
					</Grid>
					<Grid item xs={8} {...lastColumnProps}>
						<Tooltip
							placement="top"
							PopperProps={{
								sx: {
									maxHeight: '50%',
									overflow: 'auto',
									zIndex: 1000000,
									' .MuiTooltip-tooltip': { marginBottom: '0' },
								},
							}}
							title={<>{substance?.smilesCode}</>}
						>
							<OverflowText enableTooltip={true} variant="pg-m" color="grey.800" sx={{ lineHeight: '160%', width: '70%' }}>
								{substance?.smilesCode?.toUpperCase() ?? '-'}
							</OverflowText>
						</Tooltip>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="pg-m">
							<Tr.Admin path="substance-detail.synonyms" />
						</Typography>
					</Grid>
					<Grid item xs={8} {...lastColumnProps}>
						<Tooltip
							placement="top"
							PopperProps={{
								sx: {
									maxHeight: '50%',
									overflow: 'auto',
									zIndex: 1000000,
									' .MuiTooltip-tooltip': { marginBottom: '0 !important' },
								},
							}}
							title={<>{substance?.synonyms}</>}
						>
							<OverflowText enableTooltip={true} variant="pg-m" color="grey.800" sx={{ lineHeight: '160%' }}>
								{substance?.synonyms?.toUpperCase() ?? '-'}
							</OverflowText>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
