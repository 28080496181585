import { KeyboardEventHandler } from 'react';

export class FormUtils {
	static preventNonNumeric: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e' || event?.key === ',') {
			event.preventDefault();
		}
	};

	static preventNonDecimal: KeyboardEventHandler<HTMLDivElement> = (event) => {
		if (event?.key === '-' || event?.key === '+' || event?.key === 'e' || event?.key === ',') {
			event.preventDefault();
		}
	};
}
