import { Button, Typography, Stack, SxProps } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface AddUserButtonProps {
	sx?: SxProps;
	buttonName?: string;
}

export const CreateReferenceButton: React.FC<AddUserButtonProps> = ({ sx, buttonName = 'Add Reference' }) => {
	const navigate = useNavigate();

	return (
		<Stack>
			<Button data-testid="add-user-reference" variant="text" sx={{ alignItems: 'center', ...sx }} onClick={() => navigate('./new')}>
				<Add sx={{ fontSize: '20px' }} />
				<Typography variant="subtitle2" sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }} color="primary.main">
					{buttonName}
				</Typography>
			</Button>
		</Stack>
	);
};
