import { formFieldSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { UserReferencesAsyncModal } from '@components/common/UserReferencesAsyncModal';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { useIrCustomReferenceHub } from '@hooks/ir/useIrCustomerReference';
import { IUploadedFile } from '@models/file';
import { PortalPageRoutes } from '@models/router';
import { Stack, Typography } from '@mui/material';
import { getUserReferenceValidationSchema } from '@schemas/ir/user-reference';
import { irDrmService } from '@services/ir';
import { UserReferenceModel } from '@services/ir/ir-user-reference';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateRefSaveFields, UserReferenceFormLayout } from '../IrReferenceLayout';

export type FormFields = Extendable<UserReferenceModel>;

export const IrEditUserReference = () => {
	const { id = '', status, drmCode } = useParams<{ id: string; drmCode: string; status: string }>();
	const { t } = useTranslation('irportal');
	const navigate = useNavigate();

	const [userReferenceData, setUserReferenceData] = useState<UserReferenceModel>();
	const [showUpdateInProgressModal, setShowUpdateInProgressModal] = useState(false);
	const [showDeactivateInProgressModal, setShowDeactivateInProgressModal] = useState(false);
	const [showReactivateInProgressModal, setShowReactivateInProgressModal] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<IUploadedFile | null>(null);
	const [loadSubstance, setSubstanceAdded] = useState(false);
	const [fileReUploaded, setFileReUploaded] = useState(false);

	const formik = useFormik<FormFields>({
		initialValues: {
			isEditing: true,
			...userReferenceData,
		},
		validationSchema: getUserReferenceValidationSchema(t, true),
		onSubmit: () => {
			const referenceMaterialId = userReferenceData?.id;
			if (referenceMaterialId) {
				RxUtils.promisify(
					irDrmService.updateUserReference({
						id: userReferenceData?.id,
						resolution: formik?.values?.resolution,
						firstX: formik?.values?.firstX,
						lastX: formik?.values?.lastX,
						numberOfScans: formik?.values?.numberOfScans,
						physicalProductBatchNumber: formik?.values?.physicalProductBatchNumber,
						irReferenceMaterialLibraryId: formik?.values?.irReferenceMaterialLibraryId,
						name: formik.values.name,
						status: UserReferenceStatus.InProgress,
						generalComment: formik?.values?.generalComment,
						molecularWeight: formik?.values?.molecularWeight,
						casNumber: formik?.values?.casNumber,
						linearFormula: formik?.values?.linearFormula,
						synonyms: formik?.values?.synonyms,
						smilesCode: formik?.values?.smilesCode,
						samplingTechnique: formik?.values?.samplingTechnique,
					}),
					() => {
						setShowUpdateInProgressModal(true);
					},
				);
			}
		},
	});

	useEffect(() => {
		if (status === UserReferenceStatus.InProgress) {
			setShowUpdateInProgressModal(true);
		} else if (status === UserReferenceStatus.Reactivating) {
			setShowReactivateInProgressModal(true);
		} else if (status === UserReferenceStatus.Deactivating) {
			setShowDeactivateInProgressModal(true);
		}
		const body = { drmCode: drmCode };
		RxUtils.promisify(irDrmService.createUserReference(body), (res) => {
			if (res?.id) {
				RxUtils.promisify(irDrmService.fetchUserReference(res?.id), (details) => {
					const newReferenceMaterialDetails = {
						...details,
						irReferenceMaterialLibraryId: details?.libraryId?.toString() || '',
					};
					setUserReferenceData(newReferenceMaterialDetails);
					formik.setValues(newReferenceMaterialDetails);
				});
			}
		});
	}, []);

	useEffect(() => {
		formik.validateForm();
	}, [formik.values]);

	const updateActionName = 'Updating';
	const updateModalMessage = 'updated';
	const updateSuccessPlaceHolder = 'update';
	const materialIdForHub = showUpdateInProgressModal ? +formik.values.id : +id;
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useIrCustomReferenceHub(
		showUpdateInProgressModal || showDeactivateInProgressModal || showReactivateInProgressModal,
		[showUpdateInProgressModal, showDeactivateInProgressModal, showReactivateInProgressModal],
		materialIdForHub,
	);

	function handleSubstanceAdded() {
		setSubstanceAdded(true);
	}

	function handleUploadFile(data) {
		setUploadedFile(data);
		setFileReUploaded(true);
	}

	return (
		<>
			<Stack data-testid="edit-user-reference" spacing={formFieldSpacer}>
				<form onSubmit={formik.handleSubmit} data-testid="edit-reference-form-test-id">
					<Stack direction="row" alignItems="center" spacing={mainTitleSpacer} justifyContent="space-between">
						<Typography variant="h1" sx={{ color: disableRmInActiveFields ? 'grey.400' : 'text.secondary' }}>
							<Tr.IrPortal path="user-references.edit-reference" />
						</Typography>
						<CreateRefSaveFields
							uploadedFile={uploadedFile}
							formik={formik}
							editing={true}
							loadSubstance={loadSubstance}
							fileReuploaded={fileReUploaded}
						/>
					</Stack>
					<UserReferenceFormLayout
						formik={formik}
						editing={true}
						referenceFileId={formik?.values?.id}
						uploadedFile={uploadedFile}
						setUploadedFile={handleUploadFile}
						substanceAdded={handleSubstanceAdded}
					/>
				</form>

				{showUpdateInProgressModal ? (
					<UserReferencesAsyncModal
						title={t('user-references.async-updates-modal.title', {
							actionName: updateActionName,
						})}
						message={t('user-references.async-updates-modal.message', {
							actionName: updateActionName,
							message: updateModalMessage,
							successPlaceHolder: updateSuccessPlaceHolder,
						})}
						subtitle={t('user-references.async-updates-modal.subtitle', {
							actionName: updateActionName,
						})}
						onClose={() => {
							navigate(
								`/${PortalPageRoutes.PORTAL}/${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.USER_LIBRARIES}/${PortalPageRoutes.REFERENCES}`,
							);
						}}
					/>
				) : null}
			</Stack>
		</>
	);
};
