import { DeactivationConfirmPopUp, SelectMenu } from '@components/common';
import { ReferenceStatusCode, UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { IrDRMDetail } from '@components/ir-portal';
import { useIrDRMDetail } from '@hooks/ir';
import { PortalPageRoutes } from '@models/router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, MenuItemProps, Stack, Typography } from '@mui/material';
import { alertService } from '@services/core';
import { irDrmService } from '@services/ir';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { createRef, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
type TActions = 'view' | 'edit' | 'activation';
type TActionSettings = {
	titlePath: string;
	action: () => void;
	menuItemProps?: MenuItemProps;
};

type TIrUserReferencesActionsMenu = {
	id: number;
	status: string;
	drmCode: string;
	refreshRmList: () => void;
};

export const IrUserReferencesActionsMenu: FC<TIrUserReferencesActionsMenu> = ({ id, status, drmCode, refreshRmList }) => {
	const [isDeactivationConfirmVisible, setIsDeactivationConfirmVisible] = useState<boolean>(false);

	const { resetIrDetail, setIrDRMDetail, ...props } = useIrDRMDetail();

	const drmDetailRef = createRef<HTMLDivElement>();
	const navigate = useNavigate();
	const { t } = useTranslation('irportal');

	const isRmActive = status === UserReferenceStatus.Active;
	const isMenuEnabled = isRmActive || status === UserReferenceStatus.Inactive;
	const deactivate = () => {
		setIsDeactivationConfirmVisible(true);
	};
	const reactivate = () => {
		alertService.send({
			confirmTextHeader: <Tr.IrPortal path="user-references.reactiate-modal-reference-title" />,
			titleText: <Tr.IrPortal path={'user-references.confirmation'} />,
			confirmTextComponent: <Tr.IrPortal path={'user-references.reactivate'} />,
			closeTextComponent: <Tr.IrPortal path="user-references.cancel" />,
			onConfirm: () =>
				RxUtils.promisify(
					irDrmService.changeUserReferenceStatus(+id, ReferenceStatusCode.Activating, UserReferenceStatus.Active),
					refreshRmList,
				),
			content: <Tr.IrPortal path="user-references.reactivate-reference-message" />,
		});
	};

	const getMenuItemProps = () => {
		return {
			sx: {
				color: !isRmActive ? 'success.main' : 'error.main',
			},
		};
	};
	const selectMenuItems: Record<TActions, TActionSettings> = {
		edit: {
			titlePath: 'edit',
			action: () =>
				navigate(`../../${PortalPageRoutes.MANAGE_ORGANIZATION}/${PortalPageRoutes.REFERENCES}/edit/${drmCode}/${id}/${status}`),
		},
		view: {
			titlePath: 'view',
			action: () => setIrDRMDetail({ visible: true, drmId: id }),
		},
		activation: {
			titlePath: isRmActive ? 'deactivate' : 'reactivate',
			action: isRmActive ? deactivate : reactivate,
			menuItemProps: getMenuItemProps(),
		},
	};

	return (
		<>
			<SelectMenu
				items={Object.values(selectMenuItems)
					.filter((v) => isRmActive || v.titlePath !== 'edit')
					.map((v) => v.titlePath)}
				onSelect={(item) =>
					Object.values(selectMenuItems)
						.find((i) => i.titlePath === item)
						?.action()
				}
				getButton={(e) => (
					<IconButton onClick={e} disabled={!isMenuEnabled}>
						<MoreVertIcon sx={{ color: isMenuEnabled ? 'primary.main' : 'grey.500' }} />
					</IconButton>
				)}
				menuItemProps={Object.values(selectMenuItems)
					.filter((v) => isRmActive || v.titlePath !== 'edit')
					.map((v) => v.menuItemProps)}
			/>
			<DeactivationConfirmPopUp
				title={<Tr.IrPortal path={'user-references.confirmation'} />}
				subTitle={
					<>
						<Stack>
							<Typography variant="h3" lineHeight="120%">
								<Tr.IrPortal path="user-references.deactivate-reference-modal-title" />
							</Typography>
						</Stack>
						<Stack>
							<Typography variant="pg-m">{t('user-references.deactivate-reference-message')}</Typography>
						</Stack>
					</>
				}
				message={t('user-references.deactivate-reference-comment')}
				visible={isDeactivationConfirmVisible}
				onConfirm={(comment) => {
					RxUtils.promisify(
						irDrmService.changeUserReferenceStatus(+id, ReferenceStatusCode.Deactivating, comment),
						refreshRmList,
					);
				}}
				onCancel={() => setIsDeactivationConfirmVisible(false)}
			/>
			{useMemo(
				() => (
					<IrDRMDetail onCloseClick={() => resetIrDetail()} ref={drmDetailRef} showInactive={true} {...props} />
				),
				[props],
			)}
		</>
	);
};
