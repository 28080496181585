import { FC } from 'react';
import { useFormik } from 'formik';
import { Stack, Typography } from '@mui/material';
import { TableButton } from '@components/nmr-portal';
import { useTranslation } from 'react-i18next';
import { Tr } from '@utils/Translation';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FormikPhoneField } from '@components/common/FormikPhoneField';
import { getPhoneValidationSchema } from '@schemas';

type FormFields = Extendable<{
	phoneDigits: string;
	phoneCode: string;
	phoneNumber: string;
}>;

interface IPhoneNumberProps {
	onSuccessfullyEntered: (phoneNumber: string) => void;
	onBackClick: () => void;
}

export const PhoneNumber: FC<IPhoneNumberProps> = ({ onSuccessfullyEntered, onBackClick }) => {
	const { t } = useTranslation('login');
	const formik = useFormik<FormFields>({
		initialValues: {
			phoneNumber: '',
		},
		validationSchema: getPhoneValidationSchema(t),
		onSubmit: ({ phoneNumber }) => {
			onSuccessfullyEntered(phoneNumber.replace(' ', ''));
		},
	});

	return (
		<Stack>
			<Stack spacing={2}>
				<Stack>
					<Typography data-testid="phone-number-test-id" variant="h1">
						<Tr.Login path="phone-number.phone-number" />
					</Typography>
				</Stack>
				<Stack>
					<Typography variant="pg-m" sx={{ marginBottom: '2.5rem' }}>
						<Tr.Login path="phone-number.please-enter-phone" />
					</Typography>
				</Stack>
			</Stack>

			<form onSubmit={formik.handleSubmit}>
				<FormikPhoneField formik={formik} title={<Tr.Login path="phone-number.phone-number" />} />
				<Stack sx={{ flexDirection: 'row', alignItems: 'center', marginTop: '2.5rem' }}>
					<Stack
						data-testid="phone-number-back-button-id"
						onClick={() => onBackClick()}
						direction="row"
						sx={{ cursor: 'pointer', alignItems: 'center' }}
					>
						<KeyboardBackspaceIcon color="primary" />
						<Typography variant="label-s" color="primary" marginLeft="0.625rem">
							<Tr.Login path="phone-number.back" />
						</Typography>
					</Stack>

					<TableButton.Contained
						type="submit"
						data-testid="send-phone-number-id"
						sx={{ marginLeft: 'auto', display: 'flex', width: 'fit-content', height: '2.5rem' }}
						disabled={!formik.isValid || !formik.dirty}
					>
						<Tr.Login path="phone-number.send-code" />
					</TableButton.Contained>
				</Stack>
			</form>
		</Stack>
	);
};
