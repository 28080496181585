import { MultipleRoleSelector } from '@components/common/MultipleRoleSelector';
import { UserResponseModel, UserRole } from '@models/user';
import { Grid, Stack, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditUserButtons } from './EditUserButtons';
import { RxUtils } from '@utils/Rx';
import { userService } from '@services/core';
import { PatchType } from '@models/request-response';
import { DataTestId } from '@utils/DataTestId';
import { getRoleValidationSchema } from '@schemas';
import * as yup from 'yup';
import { ModalDialog } from '@components/common';
interface IEditAdminUserModal {
	onClose?: () => void;
	onSave?: () => void;
	visible: boolean;
	user: UserResponseModel;
	roles: string[];
}
type FormFields = Extendable<{ Roles: UserRole[] }>;
export const EditAdminUserModal: FC<IEditAdminUserModal> = ({ onClose, onSave, visible, user, roles }) => {
	const { t } = useTranslation('admin');

	const formik = useFormik<FormFields>({
		initialValues: { Roles: user.roles },
		validationSchema: yup.object({ Roles: getRoleValidationSchema(t) }),
		onSubmit: (values) => {
			RxUtils.promisify(
				userService.updateMerckUser(user.id, {
					op: PatchType.REPLACE,
					path: '/Roles',
					value: values.Roles,
				}),
				() => {
					onSave?.();
				},
			);
		},
	});

	const AdminInformationContent: FC<{ title: string; content: string }> = ({ title, content }) => {
		return (
			<Stack>
				<Typography variant="label-s" color="grey.800">
					{title}
				</Typography>
				<Typography variant="pg-m" paddingTop={0.5}>
					{content}
				</Typography>
			</Stack>
		);
	};

	return (
		<ModalDialog
			variant="primary"
			open={visible}
			maxWidth="sm"
			onClose={() => onClose?.()}
			sx={{ zIndex: 2 }}
			title={<Tr.Admin path={'users-and-roles.edit-user'} />}
			titleProps={{
				sx: {
					paddingTop: 4,
					marginTop: 0,
				},
			}}
			PaperProps={{
				sx: {
					maxWidth: '832px',
					maxHeight: '650px',
					minHeight: '362px',
				},
			}}
			iconButtonProps={{
				mt: 3.75,
				width: 24,
				height: 24,
			}}
			contentProps={{ sx: { padding: 3 } }}
		>
			<Stack marginTop={1} data-testid={DataTestId.getStaticTestId('modal-wrapper-test-id')}>
				<Grid container>
					<Grid item xs={6}>
						<AdminInformationContent title={t('users-and-roles.name')} content={user.fullName ?? '-'} />
					</Grid>
					<Grid item xs={6}>
						<AdminInformationContent title={t('users-and-roles.email')} content={user.email} />
					</Grid>
					<Grid item xs={12}></Grid>
				</Grid>
				<Stack marginY={4}>
					<Typography
						variant="label-s"
						color={user.isActive() ? 'grey.800' : 'grey.200'}
						className={user.isActive() ? 'required' : ''}
						marginBottom={1.25}
					>
						<Tr.Common path="role" />
					</Typography>
					<MultipleRoleSelector formik={formik} name="Roles" optionSet={roles} disabled={!user.isActive()} />
				</Stack>
				<Stack>
					<EditUserButtons
						onCancel={() => onClose?.()}
						onSave={() => formik.submitForm()}
						isValid={formik.isValid && formik.dirty}
						refreshDataCallback={() => onSave?.()}
						user={user}
					/>
				</Stack>
			</Stack>
		</ModalDialog>
	);
};
