import {
	AddUserLibraryModal,
	Body1,
	DataTableBackend,
	DefaultLibraryInfo,
	DeleteButton,
	EditButton,
	useDefaultLibrary,
} from '@components/common';
import { TableButton } from '@components/nmr-portal';
import { NmrEditLibraryModal } from '@components/nmr-portal/NmrEditLibraryModal';
import { getParams, useFilterSearchParams, useService } from '@hooks';
import { PortalPageRoutes } from '@models/router';
import { SortOrder } from '@models/sorting';
import { ExtendedColumn } from '@models/table';

import Add from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import { alertService, notificationService } from '@services/core';
import { nmrFilterService, NmrLibrary, nmrLibraryService } from '@services/nmr';
import { RxUtils } from '@utils/Rx';
import { Tr } from '@utils/Translation';
import { DateUtils } from '@utils/Type';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
type TDeleteUserLibrary = {
	id: number;
	onSuccess: () => void;
};

const DeleteUserLibrary: FC<TDeleteUserLibrary> = ({ id, onSuccess, ...props }) => {
	const { t } = useTranslation('portal');
	const sendDeleteRequest = () => {
		RxUtils.promisify(nmrLibraryService.delete(id), () => {
			notificationService.sendSuccess(t('user-references.delete-library-success-message'));
			onSuccess();
		});
	};
	const sendDeleteAlert = () => {
		alertService.send({
			confirmTextHeader: <Tr.Portal path="user-references.remove-library" />,
			titleText: <Tr.Portal path={'user-references.remove'} />,
			confirmTextComponent: <Tr.Portal path={'user-references.remove'} />,
			closeTextComponent: <Tr.Portal path="user-references.cancel" />,
			onConfirm: () => sendDeleteRequest(),
			content: <Tr.Portal path="user-references.remove-library-explanation" />,
		});
	};
	return <DeleteButton onClick={sendDeleteAlert} {...props} />;
};

export const NmrUserLibrary = () => {
	const { searchParams, setFiltering, filtering } = useFilterSearchParams();
	const [editLibrary, setEditLibrary] = useState<NmrLibrary>();
	const { t } = useTranslation('portal');
	const navigate = useNavigate();

	const { data: userLibraries, trigger: triggerUserLibraries } = useService(() => {
		searchParams.set('pageSize', '15');
		if (!searchParams.get('pageIndex')) {
			searchParams.set('pageIndex', '1');
		}
		searchParams.set('IsUserLibrary', 'true');
		return nmrLibraryService.get(searchParams);
	}, [searchParams]);

	const { data: filterData } = useService(() => nmrFilterService.getLibrariesFilter(), []);

	const [searchTextfieldValue, setSearchTextfieldValue] = useState<string>('');
	const [addUserLibraryVisible, setAddUserLibraryVisible] = useState<boolean>(false);

	useDefaultLibrary(userLibraries?.data ?? []);

	const onLinkClick = (id: number) => {
		navigate(`../${PortalPageRoutes.REFERENCES}?libraries=${id}`);
	};

	const columns: ExtendedColumn<NmrLibrary>[] = [
		{
			accessor: 'libraryName',
			disableSortBy: true,
			Header: (
				<Stack ml={1}>
					<Tr.Portal path="user-references.library" />
				</Stack>
			),
			Cell: ({ value, row }) => (
				<Stack ml={1}>
					<Typography
						variant="pg-s"
						sx={{
							color: 'primary.main',
							cursor: 'pointer',
							'&:hover': {
								textDecoration: 'underline',
							},
						}}
						onClick={() => onLinkClick(row.original.libraryId)}
					>
						{value || '-'}
					</Typography>
				</Stack>
			),
		},
		{
			accessor: 'count',
			Header: <Tr.Portal path="user-references.number-of-references" />,
			Cell: ({ value }) => <Body1 variant="pg-s">{value || '-'}</Body1>,
		},
		{
			accessor: 'createTime',
			Header: <Tr.Portal path="user-references.creation-date" />,
			Cell: ({ value }) => <Body1>{value ? DateUtils.getFormattedDate(value) : '-'}</Body1>,
		},
		{
			accessor: 'libraryId',
			Header: <></>,
			disableSortBy: true,
			Cell: ({ row }) => (
				<Stack direction="row-reverse" alignItems="center" spacing={4} mr={1} data-testid="library-id-column-id">
					{row.original.isDefault ? (
						<DefaultLibraryInfo />
					) : (
						<>
							<DeleteUserLibrary id={row.original.libraryId} onSuccess={() => triggerUserLibraries()} />
							<EditButton onClick={() => setEditLibrary(row.original)} data-testid={`edit-library-${row.index}-id`} />
						</>
					)}
				</Stack>
			),
		},
	];
	return (
		<>
			{editLibrary && (
				<NmrEditLibraryModal
					onClose={() => setEditLibrary(undefined)}
					library={editLibrary}
					onSuccess={() => (
						triggerUserLibraries(),
						setEditLibrary(undefined),
						notificationService.sendSuccess(t('user-references.edit-success-notification'))
					)}
				/>
			)}
			{addUserLibraryVisible && (
				<AddUserLibraryModal
					onClose={() => setAddUserLibraryVisible(false)}
					visible={addUserLibraryVisible}
					onSubmit={(data) => {
						RxUtils.promisify(nmrLibraryService.addLibrary(data.libraryName), () => {
							notificationService.sendSuccess(t('add-user-library.success-notification'));
							setAddUserLibraryVisible(false);
							triggerUserLibraries();
						});
					}}
				/>
			)}
			<Stack>
				<Stack direction="row" justifyContent="space-between" mb={1.5}>
					<Typography variant="h2" color="primary.main">
						<Tr.Portal path="user-references.all-libraries" />
					</Typography>
					<TableButton.Text
						data-testid="add-user-library"
						sx={{ alignItems: 'center', height: '2.5rem' }}
						onClick={() => setAddUserLibraryVisible(true)}
					>
						<Add sx={{ fontSize: '20px' }} />
						<Typography variant="subtitle2" sx={{ paddingBottom: 0.2, paddingLeft: 0.5 }} color="primary.main">
							<Tr.Portal path="add-user-library.add-library" />
						</Typography>
					</TableButton.Text>
				</Stack>
				<DataTableBackend
					data={userLibraries?.data ?? []}
					columns={columns}
					sorting={{
						sortBy: getParams(searchParams).getSortBy(),
						sortOrder: getParams(searchParams).getSortOrder() as SortOrder,
					}}
					pagination={{ pageIndex: +getParams(searchParams).getPageIndex(), totalPages: userLibraries?.totalPages ?? 1 }}
					searchText={searchTextfieldValue}
					searchPlaceholder={t('user-references.library-search-placeholder')}
					onSearchTextChange={setSearchTextfieldValue}
					searchFieldProps={{
						sx: {
							width: (400 / 1440) * window.innerWidth,
						},
					}}
					filterData={filterData?.options}
					onFilter={setFiltering}
					filteringSelections={filtering}
					sx={{
						'& .MuiTableRow-root': {
							transition: 'all 100ms ease-in',
						},
					}}
				/>
			</Stack>
		</>
	);
};
