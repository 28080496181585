import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formFieldSpacer, innerTitleSpacer, Tr } from '@utils';
import { Grid, Stack, SxProps, Typography } from '@mui/material';
import { FormikApiType, FormikTextfield, GenericCard } from '@components/common';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';

interface NmrReferenceAcquisitionParametersProps {
	userReferenceData?: any;
	formik: FormikApiType;
	sx?: SxProps;
	editing?: boolean;
}

export const NmrReferenceAcquisitionParameters: FC<NmrReferenceAcquisitionParametersProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
}) => {
	const { t } = useTranslation('portal');
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	return (
		<GenericCard sx={sx}>
			<Stack marginBottom={innerTitleSpacer}>
				<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
					<Tr.Portal path="drm-detail.acquisition-parameters" />
				</Typography>
			</Stack>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="solvent"
						title={t('drm-detail.solvent')}
						placeholder={t('user-references.placeholders.solvent')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="frequency"
						title={t('drm-detail.frequency')}
						placeholder={t('user-references.placeholders.frequency')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="relaxationTime"
						title={t('drm-detail.relaxation-time')}
						placeholder={t('user-references.placeholders.relaxation-time')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="numberOfScans"
						title={t('drm-detail.number-of-scans')}
						placeholder={t('user-references.placeholders.number-of-scans')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="acquisitionTime"
						title={t('drm-detail.acquisition-time')}
						placeholder={t('user-references.placeholders.acquisition-time')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="temperature"
						title={t('drm-detail.temperature')}
						placeholder={t('user-references.placeholders.temperature')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6}>
					<FormikTextfield
						formikApi={formik}
						name="batchNumber"
						title={t('user-references.batch-number')}
						placeholder={t('user-references.placeholders.batch-number')}
						variant="outlined"
						disabled={disableRmInActiveFields}
					/>
				</Grid>
			</Grid>
		</GenericCard>
	);
};
