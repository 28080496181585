import { FC, ReactNode, useEffect, useState } from 'react';
import { InfoIcon } from '@components/icons';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Tr } from '@utils/Translation';
import { GenericCard } from '../GenericCard';
import { ModalDialog } from '../ModalDialog';
import { useTranslation } from 'react-i18next';

interface IDeactivationConfirmPopUpProps {
	visible: boolean;
	title: ReactNode;
	subTitle?: ReactNode;
	message: string;
	textFieldTitle?: string;
	warningMessage?: ReactNode;
	cancelText?: ReactNode;
	buttonText?: ReactNode;
	onConfirm: (comment: string) => void;
	onCancel: () => void;
}

export const DeactivationConfirmPopUp: FC<IDeactivationConfirmPopUpProps> = ({
	visible,
	title,
	subTitle,
	message,
	textFieldTitle,
	warningMessage,
	onConfirm,
	onCancel,
	cancelText,
	buttonText: buttonText,
}) => {
	const [comment, setComment] = useState<string>('');
	const { t } = useTranslation('admin');

	useEffect(() => {
		setComment('');
	}, [visible]);

	return (
		<ModalDialog
			variant="secondary"
			onClose={onCancel}
			title={<Typography variant="pg-s">{title}</Typography>}
			titleProps={{ sx: { paddingBottom: 0 } }}
			onBackdropClick={onCancel}
			open={visible}
			sx={{
				'& .MuiDialog-container': {
					'& .MuiPaper-root': {
						width: '100%',
						maxWidth: '472px',
					},
				},
			}}
			PaperProps={{
				sx: {
					minWidth: '472px',
					maxWidth: '472px',
					maxHeight: '800px',
					minHeight: '466px',
				},
			}}
		>
			<GenericCard sx={{ overflow: 'auto', border: 'none', paddingTop: 0, paddingX: 8, paddingBottom: 6 }}>
				<Stack spacing={3} textAlign="center" sx={{ mt: 4 }} data-testid="deactivation-confirmation-wrapper">
					{subTitle}
					<Stack>
						<Typography variant="label-s" sx={{ marginBottom: 1.25, textAlign: 'left' }}>
							{textFieldTitle ?? t('substance-management.reason')}{' '}
							<Typography sx={{ display: 'inline', color: 'error.main' }}> *</Typography>
						</Typography>

						<TextField
							multiline
							hiddenLabel
							rows={4}
							placeholder={message}
							variant="outlined"
							sx={{ backgroundColor: 'grey.50', padding: 0 }}
							focused={false}
							InputProps={{
								sx: {
									'& 	.MuiInputBase-input': {
										fontSize: 15,
										padding: 0,
									},
								},
							}}
							value={comment}
							inputProps={{ maxLength: 4000, 'data-testid': 'deactivation-reason-id' }}
							onChange={(e) => setComment(e.target.value)}
							required
						/>
					</Stack>
					{warningMessage && (
						<Stack direction="row" alignItems="center" columnGap={1}>
							<Stack
								sx={{ minWidth: 15, maxWidth: 15, height: 15, backgroundColor: 'secondary.main', borderRadius: '50%' }}
								alignItems="center"
								justifyContent="center"
							>
								<InfoIcon sx={{ fontSize: 10 }} />
							</Stack>

							<Typography variant="body2" fontSize="12px">
								{warningMessage}
							</Typography>
						</Stack>
					)}

					<Stack direction="row" width={1} columnGap={3} sx={{ marginTop: 4 }}>
						<Button data-testid="cancel-button-id" variant="outlined" sx={{ width: 1 }} onClick={onCancel}>
							{cancelText || <Tr.Common path="cancel" />}
						</Button>
						<Button
							data-testid="deactivate-popup-button-id"
							variant="contained"
							sx={{ width: 1 }}
							disabled={!comment.trim()}
							onClick={() => onConfirm(comment)}
							color="error"
						>
							{buttonText || <Tr.Common path="deactivate" />}
						</Button>
					</Stack>
				</Stack>
			</GenericCard>
		</ModalDialog>
	);
};
