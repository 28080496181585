import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { PagedResult } from '@models/request-response';
import { NmrAddLibrary, NmrLibrary } from './nmr-library.class';
import { nmrConfig } from '../nmrConfig';

class NmrLibraryService {
	private getUrl() {
		return `${nmrConfig.url}/Library`;
	}

	get(params?: URLSearchParams) {
		return httpService.get<PagedResult<NmrLibrary>>(this.getUrl(), '', { params: params, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transformFromExist(new PagedResult<NmrLibrary>(NmrLibrary), result)),
		);
	}

	addLibrary(libraryName?: string) {
		return httpService.post<NmrAddLibrary>(this.getUrl(), '', { body: { name: libraryName }, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrLibrary, result)),
		);
	}

	put(id: number, libraryName: string) {
		return httpService
			.put(this.getUrl(), `${id}`, {
				body: {
					name: libraryName,
				},
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.put.name}]`, result)));
	}

	delete(id: number) {
		return httpService
			.delete(this.getUrl(), `${id}`, {
				errorHandler: 'notification',
			})
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.delete.name}]`, result)));
	}
}

export const nmrLibraryService = new NmrLibraryService();
