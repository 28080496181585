import { FC, useEffect, useState } from 'react';
import { MolecularFormula, SpectrumChart } from '@components/common';
import { Stack } from '@mui/material';
import { NmrDrmDetail, nmrDrmService } from '@services';
import { RxUtils, Tr } from '@utils';
import { useTranslation } from 'react-i18next';
import { NmrDRMIndicator } from '../NmrDRMDetail';
import { useMemoizedTestId } from '@hooks';

export const AnalysisResultTheoreticalSpecrumDetail: FC<{ detailData: NmrDrmDetail }> = ({ detailData }) => {
	const [drmFile, setDrmFile] = useState<string>();
	useEffect(() => {
		if (detailData) {
			RxUtils.promisify(nmrDrmService.getERMFileFromUrl(detailData.jdxFileStorageName), (data) => setDrmFile(data));
		}
	}, [detailData]);

	const { t } = useTranslation('portal');

	const memoizedTestId = useMemoizedTestId('zoom-icon');
	const chartTestId = useMemoizedTestId('chart-test');
	return (
		<Stack direction="column">
			<Stack direction="row" marginBottom={5} columnGap={4}>
				<Stack width="30.25rem">
					<NmrDRMIndicator label={<Tr.Portal path="drm-detail.cas-number" />} value={detailData?.casNumber} />
					<NmrDRMIndicator label={<Tr.Portal path="drm-detail.linear-formula" />} value={detailData?.linearFormula} />
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.molecular-weight" />}
						value={detailData?.molecularWeight ? `${detailData?.molecularWeight} ${t('drm-detail.molecular-weight-unit')}` : ''}
					/>
					<NmrDRMIndicator
						label={<Tr.Portal path="drm-detail.smiles" />}
						value={detailData?.smilesCode ? detailData?.smilesCode : '-'}
					/>
					<NmrDRMIndicator label={<Tr.Portal path="drm-detail.reference-code" />} value={detailData?.ermProductNumber} />
				</Stack>
			</Stack>
			<Stack direction="row">
				<MolecularFormula
					width={484}
					smilesFormula={detailData?.smilesCode}
					isTheoretical={true}
					sx={{
						flexGrow: 2,
						border: '1px solid',
						borderRadius: ' 12px',
					}}
					zoomIconProps={{
						'data-testid': memoizedTestId,
					}}
				/>
				<Stack
					position="relative"
					sx={{
						borderWidth: 1,
						borderStyle: 'solid',
						overflow: 'hidden',
						borderColor: 'info.700',
						maxHeight: '25rem',
						marginLeft: '2rem',
						borderRadius: '8px',
						flexGrow: 3,
					}}
				>
					<SpectrumChart
						referenceMaterialId={detailData?.id}
						title={detailData?.name}
						hideZoomIcon={true}
						isTheoretical={true}
						file={{ content: drmFile } as any}
						sx={{ width: '100%' }}
						uniqueId={chartTestId}
						infoMessage={t('search-references.theoretical-spectrum-info')}
						layout={{ margin: { pad: 10, t: 30, l: 70, r: 10, b: 150 } }}
						header={<Tr.Portal path="theoretical-spectrum" />}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};
