import { concatMap, map, tap } from 'rxjs';
import { httpService, logger } from '@services/core';
import { RxUtils, TypeUtils } from '@utils';
import { nmrConfig } from '../nmrConfig';
import { NmrDrmDetail } from './nmr-drm-detail.class';
import { IUploadedFile, PagedResult, PatchType } from '@models';
import { NmrExportDrmModel } from './export-drm-model.interface';
import { NmrDRM } from './nmr-drm.class';

class NmrDrmService {
	private getUrl() {
		return `${nmrConfig.url}/ReferenceMaterial`;
	}

	get(id: number) {
		return httpService.get<NmrDrmDetail>(this.getUrl(), `${id}`, { errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(NmrDrmDetail, result)),
		);
	}

	searchAllWithUrlParams(params: URLSearchParams) {
		return httpService.get<PagedResult<NmrDrmDetail>>(this.getUrl(), '', { errorHandler: 'notification', params: params }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.get.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			map((result) => TypeUtils.transform(PagedResult<NmrDrmDetail>, result)),
		);
	}

	getERMFile(id: number, fileType: 'Spectrus' | 'Jdx' = 'Jdx') {
		return httpService.get<string>(this.getUrl(), `${id}/files/${fileType}`, { errorHandler: 'silent' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.getERMFile.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	updateErmActivity(id: number, comment: string, value: boolean) {
		const deActive = { op: PatchType.REPLACE, path: '/IsActive', value: value };
		const reason = { op: PatchType.REPLACE, path: '/Comment', value: comment };
		return httpService
			.patch(this.getUrl(), `${id}`, { body: [deActive, reason], errorHandler: 'notification' })
			.pipe(tap((result) => logger.debug(`[${this.constructor.name}.${this.updateErmActivity.name}]`, result)));
	}

	getERMFileFromUrl(presignedUrl: string) {
		return httpService
			.get<string>(presignedUrl, ``, { errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.getERMFileFromUrl.name}]`, result)));
	}

	getSubstancesbyLibrary(params: URLSearchParams) {
		return httpService
			.get<PagedResult<NmrDRM>>(`${this.getUrl()}/UserReferenceMaterials`, ``, {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.getSubstancesbyLibrary.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
				map((result) => TypeUtils.transformFromExist(new PagedResult<NmrDRM>(NmrDRM), result)),
			);
	}

	exportAsCsv({ query, filteringData }: NmrExportDrmModel) {
		const params = new URLSearchParams();
		params.append('query', `${query || ''}`);

		filteringData?.forEach((item) => {
			const entry = Object.entries(item as {})[0];
			entry[0] && entry[1] && params.append(entry[0], `${entry[1]}`);
		});

		return httpService
			.get<Blob>(this.getUrl(), `export`, {
				params: params,
				errorHandler: 'notification',
			})
			.pipe(
				tap((result) => logger.info(`[${this.constructor.name}.${this.exportAsCsv.name}]`, result)),
				concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
			);
	}

	uploadReferenceFile(id: number, file: IUploadedFile) {
		const formData = new FormData();
		formData.append('data', file.file);
		return httpService
			.post<string>(this.getUrl(), `${id}/files`, { body: formData, errorHandler: 'notification' })
			.pipe(tap((result) => logger.info(`[${this.constructor.name}.${this.uploadReferenceFile.name}]`, result)));
	}

	createUserReference(params) {
		return httpService.post(this.getUrl(), '/userReferenceMaterial', { body: params, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.createUserReference.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	updateUserReference(reference) {
		return httpService.put(this.getUrl(), `/userReferenceMaterial`, { body: reference, errorHandler: 'notification' }).pipe(
			tap((result) => logger.info(`[${this.constructor.name}.${this.updateUserReference.name}]`, result)),
			concatMap((result) => RxUtils.valueOrThrow(result, new Error('Response is empty'))),
		);
	}

	deleteUserReference(referenceMateriaId: number) {
		return httpService.delete(this.getUrl(), `${referenceMateriaId}`, { errorHandler: 'notification' });
	}
}

export const nmrDrmService = new NmrDrmService();
