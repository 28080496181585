import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonSpacer, DateUtils, formFieldSpacer, innerTitleSpacer, mainTitleSpacer, RxUtils, Tr } from '@utils';
import { Button, Grid, Stack, SxProps, Typography } from '@mui/material';
import { AddUserLibraryModal, FormikTextfield, GenericCard } from '@components/common';
import SelectLibraryDropdown from '@components/common/SelectLibraryDropdown/SelectLibraryDropdown';
import { useService } from '@hooks';
import { useFilterSearchParams } from '@hooks';
import { notificationService } from '@services/core';
import { SingleSelectMenuItemType } from '@components/common/SingleSelectMenu';
import { IUploadedFile } from '@models/file';
import { ReactComponent as CloudUpload } from '@material-symbols/svg-600/outlined/cloud_upload.svg';
import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { NmrSampleFileUploader } from '@components/nmr-portal/NmrSampleFileUploader';
import { nmrDrmService, nmrLibraryService } from '@services/nmr';

interface NmrReferenceOverviewProps {
	userReferenceData?: any;
	formik: any;
	sx?: SxProps;
	editing?: boolean;
	referenceFileId?: number;
	setUploadedFile: (file: IUploadedFile | null) => void;
	uploadedFile: IUploadedFile | null;
}

export const NmrReferenceOverview: FC<NmrReferenceOverviewProps> = ({
	userReferenceData,
	formik,
	sx,
	editing = false,
	referenceFileId,
	setUploadedFile,
	uploadedFile,
}) => {
	const { status } = useParams<{ status: string }>();
	const disableRmInActiveFields = status === UserReferenceStatus.Inactive;
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [addUserLibraryVisible, setAddUserLibraryVisible] = useState(false);
	const [libraryOptions, setLibraryOptions] = useState<SingleSelectMenuItemType[]>([]);
	const [triggerReUpload, setTriggerReUpload] = useState(false);
	const { t } = useTranslation('portal');
	const { searchParams } = useFilterSearchParams();

	const shouldCheckForUploadFile = editing ? false : !uploadedFile;

	const handleLibraryChange = (item) => {
		const selectedId = typeof item === 'string' ? item : item?.id;

		formik.setFieldValue('nmrReferenceMaterialLibraryId', Number(selectedId));
	};

	const handleAddMoreLibraries = () => {
		setAddUserLibraryVisible(true);
	};

	const { data: userLibraries } = useService(() => {
		searchParams.set('pageSize', '999');
		if (!searchParams.get('pageIndex')) {
			searchParams.set('pageIndex', '1');
		}
		searchParams.set('IsUserLibrary', 'true');
		return nmrLibraryService.get(searchParams);
	}, [searchParams]);

	useEffect(() => {
		const formattedLibraries =
			userLibraries?.data?.map((library) => ({
				label: library.libraryName,
				id: library.libraryId.toString(),
			})) || [];
		setLibraryOptions(formattedLibraries);
	}, [userLibraries]);

	useEffect(() => {
		if (editing) {
			formik.validateForm();
		}
	}, [userReferenceData]);

	const handleFileUpload = (file) => {
		if (file) {
			if (file?.file?.name) {
				formik.setFieldValue('name', file?.file?.name);
			}
			if (referenceFileId) {
				uploadSpectrumFile(referenceFileId, file);
			}
		}
	};

	const uploadSpectrumFile = (fileId: number, file: IUploadedFile) => {
		RxUtils.promisify(
			nmrDrmService.uploadReferenceFile(fileId, file),
			(res: any) => {
				if (res && res?.uri) {
					setUploadedFile(file);
					setTriggerReUpload(false);
					notificationService.sendSuccess(t('user-references.file-upload-success'));
				}
			},
			(err) => {
				if (+err?.ErrorCode) {
					const errMessage = JSON.parse(err?.Message);
					if (errMessage) {
						if (errMessage?.saturated_values_error) {
							notificationService.sendError(errMessage?.saturated_values_error);
						}
					} else {
						notificationService.sendError(t('Error uploading'));
					}
				}
			},
		);
	};

	const openUpload = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const selectedLibraryItem = libraryOptions.find((item: any) => item.id === String(formik.values.nmrReferenceMaterialLibraryId)) || null;

	return (
		<GenericCard sx={sx}>
			<Stack marginBottom={mainTitleSpacer}>
				<Typography variant="h3" color={disableRmInActiveFields ? 'grey.400' : 'primary.main'}>
					<Tr.Portal path="user-references.overview" />
				</Typography>
			</Stack>
			<Grid container spacing={formFieldSpacer}>
				<Grid item xs={6}>
					<Stack marginBottom={innerTitleSpacer}>
						<Stack
							direction="row"
							alignSelf="space-between"
							marginBottom={buttonSpacer}
							sx={{ justifyContent: 'space-between' }}
						>
							<Stack direction="column">
								<Typography
									className="required"
									variant="h4"
									color={disableRmInActiveFields ? 'grey.400' : 'black'}
									sx={{ mt: '7px' }}
								>
									<Tr.Portal path="user-references.experimental-spectrum" />
								</Typography>
							</Stack>
							<Stack direction="column">
								{(editing && !triggerReUpload) || uploadedFile ? (
									<Button
										onClick={() => {
											setTriggerReUpload(true);
											setUploadedFile(null);
											openUpload();
											formik.setFieldValue('name', '');
										}}
										disabled={disableRmInActiveFields}
										data-testid="reUpload"
									>
										<Stack sx={{ mr: '10px' }}>
											<CloudUpload
												width={24}
												height={24}
												style={{ fill: disableRmInActiveFields ? 'grey.400' : '#01884C' }}
											/>
										</Stack>
										<Typography variant="h5" color={disableRmInActiveFields ? '' : 'primary.main'}>
											<Tr.Portal path="user-references.reupload" />
										</Typography>
									</Button>
								) : null}
							</Stack>
						</Stack>
						<Typography color={disableRmInActiveFields ? 'grey.400' : 'grey.800'} fontWeight="400">
							<Tr.Portal path="user-references.upload-ref-msg" />
						</Typography>
					</Stack>
					<NmrSampleFileUploader
						title="create-reference"
						uploadedFile={uploadedFile}
						userReferenceType
						dropzoneSx={{
							borderStyle: 'solid',
							minHeight: 333,
							backgroundColor: 'white',
							border: '1px',
							borderColor: '#E1E1EA',
						}}
						onUploadedFileChange={handleFileUpload}
						analysisHasFile={!!uploadedFile?.content}
						editing={editing}
						triggerReUpload={triggerReUpload}
						fileInputRef={fileInputRef}
						isDisabled={disableRmInActiveFields}
					/>
				</Grid>
				<Grid item xs={6} sx={{ alignContent: 'center' }}>
					<Stack marginBottom={innerTitleSpacer}>
						<Typography variant="h5" color={disableRmInActiveFields ? 'grey.400' : '#0F1A2E'}>
							<Tr.Portal path="user-references.reference-details" />
						</Typography>
					</Stack>
					<Grid item xs={12} marginBottom={formFieldSpacer}>
						<FormikTextfield
							formikApi={formik}
							disabled={shouldCheckForUploadFile || disableRmInActiveFields}
							name="name"
							title={t('user-references.reference-name')}
							placeholder={t('user-references.placeholders.reference-name')}
							variant="outlined"
							required
							showRequiredFieldOnDisable
						/>
					</Grid>
					<Grid item xs={12} marginBottom={formFieldSpacer}>
						<Stack>
							<Typography
								variant="label-s"
								sx={{
									marginBottom: 1.25,
								}}
								className="required"
								color={disableRmInActiveFields ? 'grey.400' : '#0F1A2E'}
							>
								<Tr.Portal path="user-references.library" />
							</Typography>
							<SelectLibraryDropdown
								selectedItem={
									typeof selectedLibraryItem === 'object' ? String(selectedLibraryItem?.id) : selectedLibraryItem
								}
								onSelectedItemChange={handleLibraryChange}
								onAddMore={handleAddMoreLibraries}
								libraryData={libraryOptions}
								disabled={disableRmInActiveFields}
							/>
						</Stack>
					</Grid>
					{formik.values?.createTime ? (
						<Grid item xs={12} marginBottom={formFieldSpacer}>
							<Stack>
								<Typography
									variant="label-s"
									sx={{
										marginBottom: 1.25,
									}}
									color={disableRmInActiveFields ? 'grey.400' : '#0F1A2E'}
								>
									<Tr.Portal path="user-references.creation-date" />
								</Typography>
								<Typography
									variant="body1"
									sx={{
										marginBottom: 1.25,
									}}
									color={disableRmInActiveFields ? 'grey.400' : '#5E5E72'}
								>
									{DateUtils.getFormattedDate(parseISO(formik.values?.createTime), 'dd/MM/yyyy')}
								</Typography>
							</Stack>
						</Grid>
					) : null}
				</Grid>
			</Grid>
			{addUserLibraryVisible && (
				<AddUserLibraryModal
					onClose={() => setAddUserLibraryVisible(false)}
					visible={addUserLibraryVisible}
					onSubmit={(data) => {
						RxUtils.promisify(nmrLibraryService.addLibrary(data.libraryName), (createdLibraryId) => {
							notificationService.sendSuccess(t('add-user-library.success-notification'));
							setAddUserLibraryVisible(false);
							// Adding the new library directly to the current options instead of loading
							setLibraryOptions((prevOptions) => [
								...prevOptions,
								{ label: data.libraryName, id: createdLibraryId.toString() },
							]);
							formik.setFieldValue('nmrReferenceMaterialLibraryId', createdLibraryId);
						});
					}}
				/>
			)}
		</GenericCard>
	);
};
