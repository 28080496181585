import { SelectMenu } from '@components/common';
import { UserReferenceStatus } from '@components/common/UserReferenceStatus';
import { NmrDRMDetail } from '@components/nmr-portal';
import { useDRMDetail } from '@hooks/nmr';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, MenuItemProps } from '@mui/material';
import { createRef, FC, useMemo } from 'react';

type TActions = 'view';
type TActionSettings = {
	titlePath: string;
	action: () => void;
	menuItemProps?: MenuItemProps;
};

type TNmrUserReferencesActionsMenu = {
	id: number;
	status: string;
};

export const NmrUserReferencesActionsMenu: FC<TNmrUserReferencesActionsMenu> = ({ id, status }) => {
	const { resetDetail, setDRMDetail, ...props } = useDRMDetail();

	const drmDetailRef = createRef<HTMLDivElement>();

	const isRmActive = status === UserReferenceStatus.Active;
	const isMenuEnabled = isRmActive || status === UserReferenceStatus.Inactive;

	const selectMenuItems: Record<TActions, TActionSettings> = {
		view: {
			titlePath: 'view',
			action: () => setDRMDetail({ visible: true, drmId: id }),
		},
	};

	return (
		<>
			<SelectMenu
				items={Object.values(selectMenuItems)
					.filter((v) => isRmActive || v.titlePath !== 'edit')
					.map((v) => v.titlePath)}
				onSelect={(item) =>
					Object.values(selectMenuItems)
						.find((i) => i.titlePath === item)
						?.action()
				}
				getButton={(e) => (
					<IconButton onClick={e} disabled={!isMenuEnabled}>
						<MoreVertIcon sx={{ color: isMenuEnabled ? 'primary.main' : 'grey.500' }} />
					</IconButton>
				)}
				menuItemProps={Object.values(selectMenuItems)
					.filter((v) => isRmActive || v.titlePath !== 'edit')
					.map((v) => v.menuItemProps)}
			/>
			{useMemo(
				() => (
					<NmrDRMDetail onCloseClick={() => resetDetail()} ref={drmDetailRef} showInactive={true} {...props} />
				),
				[props],
			)}
		</>
	);
};
