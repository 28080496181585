import { OverflowText } from '@components/common';
import { Stack, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IndicatorType {
	label?: ReactNode;
	value?: string | number;
	containerSx?: SxProps;
	preventOverflow?: boolean;
	isActive?: boolean;
}

export const NmrDRMIndicator: FC<IndicatorType> = ({ label, value = '', preventOverflow = false, containerSx, isActive = true }) => (
	<Stack direction="row" alignItems="center" sx={{ mt: 1.5, ...containerSx }}>
		<Typography
			variant="pg-m"
			sx={{ width: 222, minWidth: 222, fontWeight: 400, color: isActive ? 'text.primary' : 'grey.500', marginRight: 4 }}
		>
			{label}
		</Typography>
		{preventOverflow && (
			<OverflowText variant="pg-m" sx={{ color: isActive ? 'grey.800' : 'grey.500', lineHeight: 1.6 }} enableTooltip>
				{value || '-'}
			</OverflowText>
		)}
		{!preventOverflow && (
			<Typography variant="pg-m" sx={{ color: isActive ? 'grey.800' : 'grey.500', lineHeight: 1.6 }}>
				{value || '-'}
			</Typography>
		)}
	</Stack>
);
